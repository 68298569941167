


































import Usuario from '@/entidades/Maestro/Usuario';
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
@Component
export default class extends Vue
{
   @Prop({type:Boolean,required:true})
   show!:Boolean    

    //variables
    userName:string="";
    nombres:string="";
    apellidos:string="";
    password:string="";
    confPassword:string="";
    email:string="";


    //computed

    get validarPassword()
    {
        if(this.password == this.confPassword && this.password != "" && this.confPassword != "")
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    guardar()
    {
        var usuario = new Usuario();
        usuario.usuarioId = "";
        usuario.nombres = this.nombres;
        usuario.apellidos = this.apellidos;
        usuario.password = this.password;
        usuario.email = this.email;
        usuario.userName = this.userName;

        this.$emit('guardar',usuario);
    }

    mounted() {
      this.userName = "";
      this.nombres = "";
      this.apellidos = "";
      this.password = "";
      this.confPassword = "";
    }

}
