import ResponseCustom from '../ResponseCustom';
import ResponseGenerico from '../ResponseGenerico';
import {Save,Obtener} from '../../Generico/Conexion';
import Usuario2 from './Usuario2';
import { mainAxios } from '@/plugins/axios';
export default class Usuario
{
    usuarioId?:string;
    userName?:string;
    nombres?:string;
    apellidos?:string;
    email?:string;
    password?:string;
    estado?:Boolean;
    plantas!:Array<number>;
    perfilId!:number;
    rolId!:number;
    sistemaId!:number;
    fullName!:string;

    static async  Guardar(model:Usuario):Promise<ResponseGenerico>
    {
        return await Save<Usuario>('usuario/agregar',model,mainAxios);
    }

    static async  Editar(model:Usuario):Promise<ResponseGenerico>
    {
        return await Save<Usuario>('usuario/editar',model,mainAxios);
    }

    static async  Obtener():Promise<ResponseCustom<Usuario>>
    {
        return await Obtener<Usuario>('usuario/obtener',mainAxios);
    }

    static async  ObtenerUsuarioSistema(sistemaId:number):Promise<ResponseCustom<Usuario2>>
    {
        return await Obtener<Usuario2>(`usuario/ObtenerUsuarioSistema/${sistemaId}`,mainAxios);
    }

    static async  ObtenerSelect():Promise<ResponseCustom<Usuario>>
    {
        return await Obtener<Usuario>('usuario/obtenerselect',mainAxios);
    }

    static async GuardarPlantas(usuario:Usuario):Promise<ResponseGenerico>
    {
        return await Save<Usuario>("usuario/guardarplanta",usuario,mainAxios);
    }

    static async GuardarPerfil(usuario:Usuario):Promise<ResponseGenerico>
    {
        return await Save<Usuario>("usuario/GuardarPerfil",usuario,mainAxios);
    }

    static async GuardarRol(usuario:Usuario):Promise<ResponseGenerico>
    {
        return await Save<Usuario>("usuario/GuardarRol",usuario,mainAxios);
    }

    static async  ObtenerRolName(rolName:string,sistemaId:number):Promise<ResponseCustom<Usuario2>>
    {
        return await Obtener<Usuario2>(`usuario/ObtenerUsuarioRol/${rolName}/${sistemaId}`,mainAxios);
    }
}