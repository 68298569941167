import { mainAxios } from "@/plugins/axios";
import {Obtener} from '@/Generico/Conexion';
export class SistemaModal
{
    sistemaId:number =0 ;
    descripcion:string ="";
    estado:boolean = true;

    constructor(_sistemaId:number,_descripcion:string)
    {
        this.sistemaId = _sistemaId;
        this.descripcion = _descripcion;
    }
}

export class ProcesoModel{
    procesoId:number =0 ;
    descripcion:string ="";
    constructor(_procesoId:number,_descripcion:string)
    {
        this.procesoId = _procesoId;
        this.descripcion = _descripcion;
    }
}

export class ProcesoEmpleaodModel
{
  procesoId:number = 0;
  descripcion:string ="";
  sistemaId:number = 0;
  constructor(_procesoId:number,_descripcion:string,_sistemaId:number)
  {
    this.procesoId = _procesoId;
    this.descripcion = _descripcion;
    this.sistemaId = _sistemaId;
  }
}

export class ServicioModel
{
    servicioId:number =0 ;
    descripcion:string ="";
    procesoId:number = 0;
    constructor(servicioId:number,_descripcion:string,_procesoId:number)
    {
        this.servicioId = servicioId;
        this.descripcion = _descripcion;
        this.procesoId = _procesoId;
    }
}

export class PagooModel
{
    pagoId:number =0 ;
    descripcion:string ="";
    constructor(_pagoId:number,_descripcion:string)
    {
        this.pagoId = _pagoId;
        this.descripcion = _descripcion;
    }
}

export async function ObtenerSistema()
{
    return await Obtener<SistemaModal>('sistema/ObtenerSistema',mainAxios);
}


export function listadoSistema() :Array<SistemaModal>
{
    let listado = Array<SistemaModal>();
    listado.push(new SistemaModal(1,"Forestal"));
    listado.push(new SistemaModal(2,"KTB"));
    listado.push(new SistemaModal(3,"Madera verde"));
    listado.push(new SistemaModal(4,"Administracion de Pago"));
    return listado;
}

export function listadoProceso():Array<ProcesoModel>
{
    let listado = Array<ProcesoModel>();
    listado.push(new ProcesoModel(1,"FORESTAL"));
    listado.push(new ProcesoModel(2,"KTB"))
    return listado;
}

export function listadoProcesoEmpleado():Array<ProcesoEmpleaodModel>
{
    let listado = Array<ProcesoEmpleaodModel>();
    listado.push(new ProcesoEmpleaodModel(1,"COSECHA",1));
    listado.push(new ProcesoEmpleaodModel(2,"PLANTACION",1))
    listado.push(new ProcesoEmpleaodModel(3,"GENERAL",2))
    return listado;
}


export function listadoServicio():Array<ServicioModel>
{
    let listado = Array<ServicioModel>();
    listado.push(new ServicioModel(1,"PARTE",1));
    listado.push(new ServicioModel(2,"TRANSPORTE",2))
    listado.push(new ServicioModel(3,"ASERRADO",2))
    return listado;
}

export function listadoPago():Array<PagooModel>
{
    let listado = Array<PagooModel>();
    listado.push(new PagooModel(1,"PENDIENTE"));
    listado.push(new PagooModel(2,"CANCELADO"));
    return listado;
}
