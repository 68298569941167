






















































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
@Component
export default class extends Vue {
  //props
  @Prop({ type: Array, required: true })
  header!: [];

  @Prop({ type: Array, required: true })
  items!: [];

  @Prop({ type: String, required: true })
  title!: String;

  //variables
  search: String = "";
  color: String = "Success";
  //Metodos
  //Estas son las computed
  UpdateColor(valor: Boolean): String {
    if (valor == true) {
      return "success";
    } else {
      return "red lighten-1";
    }
  }
  UpdateTexto(valor: Boolean): String {
    if (valor == true) {
      return "Activo";
    } else {
      return "Inactivo";
    }
  }
}
