









































import {Action} from 'vuex-class';
import { Perfil } from '@/entidades/Maestro/Perfil';
import Usuario from '@/entidades/Maestro/Usuario';
import { ObtenerSistema, SistemaModal } from '@/entidades/Sistema/SitemaModal';
import { Component, Prop,Vue } from 'vue-property-decorator'
import Loading from '@/entidades/Sistema/Loading';
import Alerta from '@/entidades/Sistema/Alerta';
@Component
export default class extends Vue
{

    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;

    @Prop({type:Boolean,required:true})
    dialog!:Boolean;

    @Prop({type:Object,required:false})
    usuario!:Usuario;


    moduloId:number=0;
    perfilId:number=0;
    listadoPerfil:Array<Perfil>=[];
    listadoSistema:Array<SistemaModal> =[];
    sistemaId:number =0;
   

    //compuited

    get habilitarBoton()
    {
        if(this.perfilId >0)
        {
            return true;
        }
        else
        {
            return false;
        }
    }



    //
    async obtenerPerfil()
    {
       try
       {
           //vamnos a buscar el perfil basado en el modulo
           var rsp = await Perfil.ObtenerPerfilModulo(this.sistemaId);
           if(rsp.isSuccess == true)
           {
               this.listadoPerfil = rsp.isResult;
           }
           else
           {
               alert(rsp.isMessage);
           }

       }
       catch(error)
       {

       }
    }

    async guardar()
    {
        try
        {
            this.changeLoading(new Loading(true,"Registrando informacion"));
            var usuario = this.usuario;
            usuario.perfilId = this.perfilId;
            usuario.sistemaId = this.sistemaId;
            var rsp = await Usuario.GuardarPerfil(usuario);
            if(rsp.isSuccess == true)
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000))
                this.$emit('limpiar');
            }
            else
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000))
            }
        }
        catch(error)
        {
            this.changeAlerta(new Alerta(error,true,"danger",3000))
        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }
    async mounted() 
    {
        this.listadoSistema = (await ObtenerSistema()).isResult;
      //  this.UpdatePlantas();
    }
}
