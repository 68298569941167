import Menu from "../Menu";
import SubMenu from "./SubMenu";
import {Obtener,Save,Editar} from '../../Generico/Conexion';
import ResponseCustom from '../ResponseCustom';
import ResponseGenerico from '../ResponseGenerico';
import { mainAxios } from '@/plugins/axios';
class Perfil 
{
    perfilId!:number;
    descripcion!:string;
    moduloId!:number;
    moduloDescripcion!:string;
    estado!:boolean;

    perfilPrincipal!:Array<PerfilPrincipal>;

    menuPerfil!:Array<number>;
    menuOpcion!:Array<number>;
    menuId:Array<number>=[];


    static async Agregar(perfil:Perfil):Promise<ResponseGenerico>
    {
        return await Save<Perfil>("perfil/agregar",perfil,mainAxios);
    }

    static async Obtener():Promise<ResponseCustom<Perfil>>
    {
        return await Obtener<Perfil>("perfil/obtener",mainAxios);
    }

    static async ObtenerPerfilModulo(sistemaId:number):Promise<ResponseCustom<Perfil>>
    {
        return await Obtener<Perfil>(`perfil/ObtenerPerfilModulo/${sistemaId}`,mainAxios);
    }

    
}

class PerfilPrincipal
{
    perfilPrincipalId!:number;
    perfilId!:number;
    menuId!:number;
    perfilDescripcion!:string;
    menuDescripcion!:string;
    perfil!:Perfil;
    menu!:Menu;
    perfilSubMenu!:Array<PerfilSubMenu>;
}

class PerfilSubMenu
{
    perfilSubMenuId!:number;
    perfilPrincipalId!:number;
    subMenuId!:number;
    subMenuDescripcion!:string;
    perfilPrincipal!:PerfilPrincipal;
    subMenu!:SubMenu
    
}



export {Perfil,PerfilPrincipal,PerfilSubMenu};