

































































import Vue from 'vue'
import Component from 'vue-class-component';
import Tabla from '@/components/Maestro/Usuario/Tabla.vue';
import Usuario from '../entidades/Maestro/Usuario';
import Agregar from '@/components/Maestro/Usuario/Agregar.vue';
import {Planta,ObtenerPlantaSelect} from '@/entidades/Maestro/Planta';
import PlantaUsuario from '@/components/Maestro/Usuario/PlantaUsuario.vue';
import PerfilUsuario from '@/components/Maestro/Usuario/PerfilUsuario.vue';
import Modulo from '@/entidades/Modulo';
@Component({
    components:{
        Tabla,
        Agregar,
        PlantaUsuario,
        PerfilUsuario,
        'Rol' : () => import('@/components/Maestro/Usuario/RolUsuario.vue'),
        'changePassword' : () => import('@/components/Security/ChangePassword.vue')

    }
})
export default class extends Vue
{
    listado?:Array<Usuario>=[];
    modalUsuario:boolean=false;
    componenteUsuario:string="";
    header:{text:string, align: string, sortable:boolean,value:string}[] =[
            {text:"Usuario",align: 'start',sortable:true,value:'userName'},
            {text:"Email",align: 'start',sortable:true,value:'email'},
            {text:"Nombres",align: 'start',sortable:true,value:'nombres'},
            {text:"Apellidos",align: 'start',sortable:true,value:'apellidos'},
            {text:"Rol",align: 'center',sortable:true,value:'rol'},
            {text:"Planta",align: 'center',sortable:true,value:'planta'},
            {text:"Perfil", align: 'center', sortable:true,value:'perfil'},
            {text:"Editar", align: 'center', value: 'action', sortable: false },
            {text:"Estado", align: 'center', sortable:true,value:'estado'}  ]
        
    //varialbes bool
    showModal:boolean=false;
    modalShowPlanta:boolean=false;
    modalPlanta:string="";
    modalShowPerfil:boolean=false;
    modalPerfil:string="";
    listadoPlanta:Array<Planta>=[];
    listadoModulo:Array<Modulo>=[];
    usuarioEditar:Usuario = new Usuario();
    usuarioId:string ="";
    
    ChangePasswordOpen(usuario:Usuario)
    {
        this.usuarioId = usuario.usuarioId!;
        this.componenteUsuario = "changePassword";
        this.modalUsuario = true;
       
    }
    closeModal()
    {
        this.modalUsuario = false;
        this.componenteUsuario = "";
    }
    //Metodos
    async Obtener()
   {
       try
       {
           var rsp = await Usuario.Obtener();
           if(rsp.isSuccess == true)
           {
               this.listado = rsp.isResult;
           }
           else
           {
               console.log(rsp.isMessage);
           }
       }
       catch(error)
       {
           console.log(error);
       }
   } 

   async guardar(usuario:Usuario)
   {
       try
       {
           var rsp= await Usuario.Guardar(usuario);
           console.log(rsp);
           if(rsp.isSuccess == true)
           {
               this.showModal = false;
               await this.Obtener();

           }
           else{
               console.log(rsp.isMessage);
           }
       }
       catch(error)
       {
           console.log(error);
       }
   }

    async agregarPlanta(usuario:Usuario)
   {
       try
       {
           
           var rsp =  await ObtenerPlantaSelect();
           if(rsp.isSuccess == true)
           {
               this.usuarioEditar = usuario;
               this.listadoPlanta = rsp.isResult;
               this.modalShowPlanta = true;
               this.modalPlanta = 'PlantaUsuario';
           }
           else
           {
               console.log(rsp.isMessage);
           }
       }
       catch(error)
       {
           console.log(error);
       }
   }

    async guardarPlanta(plantas:Array<number>)
    {
        try
        {
            this.usuarioEditar.plantas = plantas;
            var rsp = await Usuario.GuardarPlantas(this.usuarioEditar);
            if(rsp.isSuccess == true)
            {
                this.modalShowPlanta = false;
                
            }
            else
            {
                alert(rsp.isMessage);
            }
        }
        catch(error)
        {

        }
    }

    async agregarRol(usuario:Usuario)
    {
       this.usuarioEditar = usuario;
       this.componenteUsuario = "Rol";
       this.modalUsuario = true;

    }

    async guardarPerfil(perfilId:number)
    {
        
    }

    agregarPerfil(usuario:Usuario)
    {
        this.usuarioEditar = usuario;
        this.modalPerfil = "PerfilUsuario";
        this.modalShowPerfil = true;
        

    }
    limpiar()
    {
        this.modalUsuario = false;
        this.modalShowPerfil=false;
        this.modalPerfil = "";
        this.componenteUsuario = "";
        this.Obtener();
    }

    get Property()
    {
        if(this.componenteUsuario === "Rol")
        {
            return {'usuario' : this.usuarioEditar}
        }
        else if(this.componenteUsuario === "PerfilUsuario")
        {
            return {'usuario' : this.usuarioEditar}
        }
        else if(this.componenteUsuario = "changePassword")
        {
            return {'usuarioId'  : this.usuarioId};
        }
    }
   //ciclo de vidao
   mounted() 
   {
       this.Obtener();
       
   }
}
