
































import {Planta} from '@/entidades/Maestro/Planta';
import Usuario from '@/entidades/Maestro/Usuario';
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
@Component
export default class extends Vue
{
    @Prop({type:Boolean,required:true})
    dialog!:Boolean;

    @Prop({type:Array,required:true})
    listadoPlanta!:Array<Planta>

    @Prop({type:Object,required:false})
    usuario!:Usuario;


    plantas:Array<number>=[];


    //
    UpdatePlantas()
    {
        console.log('ehec')
        if(this.usuario.plantas.length ==0){
            //return this.plantas;
        }
        else{
            this.plantas = this.usuario.plantas;
           // return this.plantas;
        }
    }

    mounted() 
    {
        this.UpdatePlantas();
    }
}
