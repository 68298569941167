import ResponseCustom from '@/entidades/ResponseCustom';
import {Save,Obtener,Editar} from '@/Generico/Conexion';
import { mainAxios } from '@/plugins/axios';
export class Planta
{
    plantaId!:number;
    descripcion!:string;
    estado!:boolean;
    sap!:string;
    siglas!:string;

    
}

export async function  ObtenerPlanta(): Promise<ResponseCustom<Planta>>
    {
        return await Obtener<Planta>("planta/obtener",mainAxios);
    }

export async function ObtenerPlantaSelect(): Promise<ResponseCustom<Planta>>
    {
        return await Obtener<Planta>("planta/obtenerselect",mainAxios);
    }